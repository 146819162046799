import axios from 'axios';
import { io } from 'socket.io-client';
import { useAuth } from '@/components/auth/LoginAuth';
const logger = {
    debug: (...args) => process.env.NODE_ENV !== 'production' && console.debug('[Debug]:', ...args),
    info: (...args) => console.info('[Info]:', ...args),
    error: (...args) => console.error('[Error]:', ...args),
    warn: (...args) => console.warn('[Warning]:', ...args)
};
class SocketRateLimitStore {
    constructor() {
        this.store = new Map();
    }
    clear() {
        this.store.clear();
    }
}
class ApiClient {
    constructor() {
        this.maxRetries = 3;
        this.retryDelay = 1000;
        this.isRefreshing = false;
        this.failedQueue = [];
        const baseURL = process.env.API_URL || (process.env.NODE_ENV === 'production'
            ? 'https://myklinik.io'
            : 'http://localhost:4010');
        logger.info('Initializing API client with baseURL:', baseURL);
        const config = {
            baseURL,
            timeout: 100000,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            retries: 3
        };
        this.axiosInstance = axios.create(config);
        this.socketRateLimitStore = new SocketRateLimitStore(); // Initialize here
        this.setupInterceptors();
    }
    static getInstance() {
        if (!ApiClient.instance) {
            ApiClient.instance = new ApiClient();
        }
        return ApiClient.instance;
    }
    processQueue(error, token = null) {
        this.failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            }
            else {
                prom.resolve(token);
            }
        });
        this.failedQueue = [];
    }
    getSocketState() {
        return {
            connected: false,
            reconnecting: false,
            error: null
        };
    }
    // Login
    async handleTokenRefresh(config) {
        try {
            if (!this.isRefreshing) {
                this.isRefreshing = true;
                const auth = useAuth();
                const success = await auth.refreshToken();
                if (!success) {
                    this.processQueue(new Error('Failed to refresh token'));
                    auth.logout();
                    window.location.href = '/login';
                    return Promise.reject('Auth failed');
                }
                const newToken = sessionStorage.getItem('token');
                this.processQueue(null, newToken);
                this.isRefreshing = false;
                if (config) {
                    config.headers.Authorization = `Bearer ${newToken}`;
                }
            }
            return config;
        }
        catch (error) {
            this.processQueue(error);
            return Promise.reject(error);
        }
    }
    setupInterceptors() {
        this.axiosInstance.interceptors.request.use(async (config) => {
            const token = sessionStorage.getItem('token');
            if (token) {
                try {
                    const tokenData = JSON.parse(atob(token.split('.')[1]));
                    const isExpired = tokenData.exp * 1000 < Date.now();
                    if (isExpired) {
                        return this.handleTokenRefresh(config);
                    }
                }
                catch (error) {
                    logger.error('Token parsing error:', error);
                }
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }, (error) => {
            logger.error('Request interceptor error:', error);
            return Promise.reject(error);
        });
        this.axiosInstance.interceptors.response.use((response) => {
            logger.info('Response received:', response.data);
            return response;
        }, async (error) => {
            logger.error('Response error:', error);
            const originalRequest = error.config;
            if (error.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                if (!this.isRefreshing) {
                    try {
                        await this.handleTokenRefresh(originalRequest);
                        return this.axiosInstance(originalRequest);
                    }
                    catch (refreshError) {
                        return Promise.reject(refreshError);
                    }
                }
                return new Promise((resolve, reject) => {
                    this.failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    return this.axiosInstance(originalRequest);
                })
                    .catch(err => Promise.reject(err));
            }
            return Promise.reject(error);
        });
        this.axiosInstance.interceptors.response.use((response) => response, async (error) => {
            if (error.response?.status === 401 && !error.config._retry) {
                if (window.location.pathname === '/') {
                    return Promise.reject(error);
                }
                const auth = useAuth();
                await auth.logout();
                return Promise.reject(error);
            }
            return Promise.reject(error);
        });
    }
    async retryRequest(requestFn) {
        for (let i = 0; i < this.maxRetries; i++) {
            try {
                const response = await requestFn();
                return response.data.data;
            }
            catch (error) {
                if (i === this.maxRetries - 1)
                    throw error;
                await new Promise((resolve) => setTimeout(resolve, this.retryDelay * (i + 1)));
            }
        }
        throw new Error('Max retries exceeded');
    }
    async get(url) {
        return this.retryRequest(() => this.axiosInstance.get(url));
    }
    async post(url, data) {
        const response = await this.axiosInstance.post(url, data);
        if (url === process.env.LOGIN_ENDPOINT) {
            return response.data;
        }
        return response.data.data;
    }
    async login(email, password) {
        const loginEndpoint = process.env.LOGIN_ENDPOINT;
        logger.info('Attempting login:', {
            email,
            endpoint: loginEndpoint,
            baseURL: this.axiosInstance.defaults.baseURL
        });
        try {
            const response = await this.axiosInstance.post('/api/auth/login', {
                email,
                password
            });
            logger.info('Login successful:', response.data);
            return response.data;
        }
        catch (error) {
            logger.error('Login failed:', {
                message: error.message,
                code: error.code,
                endpoint: loginEndpoint,
                config: error.config
            });
            throw error;
        }
    }
    async logout(email, password) {
        return this.post('/api/auth/logout', { email, password });
    }
    cleanup() {
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        if (this.axiosInstance) {
            this.axiosInstance.interceptors.request.clear();
            this.axiosInstance.interceptors.response.clear();
        }
        this.failedQueue = [];
        this.isRefreshing = false;
        if (window.socket) {
            window.socket.disconnect();
            delete window.socket;
        }
        if (this.socketRateLimitStore) {
            this.socketRateLimitStore.clear();
        }
        logger.info('API client cleanup completed');
    }
    // Rooms
    async getRoomsByCity(city) {
        try {
            const response = await this.get(`/api/rooms/${city.toLowerCase()}/rooms`);
            if (!response.data) {
                return []; // Return empty array if no data
            }
            return response.data;
        }
        catch (error) {
            logger.error('Error fetching rooms:', error);
            return []; // Return empty array on error
        }
    }
    async updateRoomStatus(city, room, slotType, roomReservedBy, newStatus, updatedReservations) {
        return this.post('/api/rooms/update', {
            city,
            room,
            updates: {
                operation: roomReservedBy ? 'reserve' : 'cancel',
                reserveStatus: newStatus,
                roomReserved: updatedReservations || []
            }
        });
    }
    async getHelsinkiRooms() {
        return this.get('/api/rooms/rooms_helsinki');
    }
    async getTurkuRooms() {
        return this.get('/api/rooms/rooms_turku');
    }
    async getOuluRooms() {
        return this.get('/api/rooms/rooms_oulu');
    }
    async getKuopioRooms() {
        return this.get('/api/rooms/rooms_kuopio');
    }
    // User status
    async updateUserStatus(city, data) {
        return this.post(`/api/users/${city}/userstatus`, data);
    }
    // Chat
    async toggleChat(data) {
        return this.post('/api/chat/toggle', data);
    }
    async markMessagesAsOpened(data) {
        return this.post('/api/chat/mark-opened', data);
    }
    async sendMessage(data) {
        return this.post('/api/chat/send', data);
    }
    subscribeToRoomUpdates(city, room, callback) {
        const socket = io(process.env.SOCKET_URL || 'http://localhost:4010', {
            auth: {
                token: localStorage.getItem('token')
            }
        });
        const roomChannel = `roomUpdate:${city}:${room}`;
        socket.on(roomChannel, callback);
        return () => {
            socket.off(roomChannel);
            socket.disconnect();
        };
    }
    async getReleases(city) {
        const response = await this.axiosInstance.get(`/api/releases/releases_${city.toLowerCase()}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        });
        return response.data;
    }
}
export const apiClient = ApiClient.getInstance();
export default apiClient;
