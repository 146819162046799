import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './LoginAuth';
import { Alert, AlertDescription } from '../common/alert';
import apiClient from '@/api/api.client';
;
const SESSION_DURATION = 4 * 60 * 60 * 1000; // 4 hours
const WARNING_THRESHOLD = 0.75; // Show warning at 75% of session duration
const WARNING_DURATION = 5 * 60 * 1000; // 5 minutes warning period
const ACTIVITY_CHECK_INTERVAL = 60 * 1000; // Check every minute
const INACTIVITY_THRESHOLD = 30 * 60 * 1000; // 30 minutes
const SessionManager = () => {
    const navigate = useNavigate();
    const { logout, userCity, user } = useAuth();
    const [sessionState, setSessionState] = useState({
        lastActivity: Date.now(),
        warningShown: false,
    });
    const [showWarning, setShowWarning] = useState(false);
    const [remainingTime, setRemainingTime] = useState(SESSION_DURATION);
    const updateLastActivity = useCallback(() => {
        setSessionState(prev => ({
            ...prev,
            lastActivity: Date.now(),
            warningShown: false,
        }));
        setShowWarning(false);
    }, []);
    const handleUserActivity = useCallback(() => {
        updateLastActivity();
    }, [updateLastActivity]);
    const handleLogout = async () => {
        try {
            if (userCity && user?.userId) {
                await apiClient.post(`/api/users/${userCity}/userstatus`, {
                    userId: user.userId,
                    status: 'offline',
                    lastLogin: new Date().toISOString()
                });
            }
            await logout();
            navigate('/login');
        }
        catch (error) {
            console.error('Error during logout:', error);
            await logout();
            navigate('/login');
        }
    };
    const checkSession = useCallback(async () => {
        const now = Date.now();
        const timeSinceLastActivity = now - sessionState.lastActivity;
        if (timeSinceLastActivity >= SESSION_DURATION) {
            await handleLogout();
            return;
        }
        // Check for inactivity
        if (timeSinceLastActivity >= INACTIVITY_THRESHOLD) {
            setShowWarning(true);
            if (!sessionState.warningShown) {
                setSessionState(prev => ({ ...prev, warningShown: true }));
            }
        }
        // Update remaining time
        const timeRemaining = SESSION_DURATION - timeSinceLastActivity;
        setRemainingTime(timeRemaining);
        // Show warning when approaching session expiration
        if (timeRemaining <= WARNING_DURATION && !sessionState.warningShown) {
            setShowWarning(true);
            setSessionState(prev => ({ ...prev, warningShown: true }));
        }
    }, [sessionState.lastActivity, sessionState.warningShown, handleLogout]);
    // Set up activity listeners
    useEffect(() => {
        const events = [
            'mousedown',
            'keydown',
            'touchstart',
            'mousemove',
            'scroll',
            'click'
        ];
        events.forEach(event => {
            window.addEventListener(event, handleUserActivity);
        });
        return () => {
            events.forEach(event => {
                window.removeEventListener(event, handleUserActivity);
            });
        };
    }, [handleUserActivity]);
    // Set up session check interval
    useEffect(() => {
        const intervalId = setInterval(checkSession, ACTIVITY_CHECK_INTERVAL);
        return () => clearInterval(intervalId);
    }, [checkSession]);
    // Handle session extension
    const handleExtendSession = async () => {
        try {
            const response = await apiClient.post('/api/auth/refresh', {});
            // Type guard for response
            if (response && 'success' in response && response.success && response.data?.token) {
                updateLastActivity();
                setShowWarning(false);
                // Update the token in storage
                localStorage.setItem('token', response.data.token);
            }
            else {
                throw new Error('Invalid response format');
            }
        }
        catch (error) {
            console.error('Failed to extend session:', error);
            await handleLogout();
        }
    };
    if (!showWarning)
        return null;
    return (_jsx("div", { className: "fixed bottom-4 right-4 w-96 z-50", children: _jsx(Alert, { className: "bg-yellow-50 border-yellow-400", children: _jsx(AlertDescription, { children: _jsxs("div", { className: "space-y-2", children: [_jsx("p", { children: remainingTime <= WARNING_DURATION
                                ? `Your session will expire in ${Math.ceil(remainingTime / 60000)} minutes.`
                                : 'You have been inactive for an extended period.' }), _jsxs("div", { className: "flex justify-end space-x-2 mt-2", children: [_jsx("button", { onClick: handleExtendSession, className: "px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600", children: "Extend Session" }), _jsx("button", { onClick: handleLogout, className: "px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600", children: "Logout" })] })] }) }) }) }));
};
export default SessionManager;
