import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/auth/LoginAuth';
import PrivateRoute from './components/auth/PrivateRoute';
import SessionManager from './components/auth/SessionManager';
import { LoadingSpinner } from './components/common/LoadingSpinner';
;
// Lazy load components
const MyKlinikio = React.lazy(() => import('./pages/myklinikUI'));
const LoginForm = React.lazy(() => import('./pages/loginForm'));
const StudentUI = React.lazy(() => import('./pages/studentUI'));
const TeacherUI = React.lazy(() => import('./pages/teacherUI'));
const NotificationUI = React.lazy(() => import('./pages/notificationUI'));
// Socket connection manager component
const SocketManager = React.lazy(() => import('./components/socket/SocketManager'));
// Define valid cities array
const VALID_CITIES = ['helsinki', 'turku', 'oulu', 'kuopio'];
const App = () => {
    return (_jsx(Router, { children: _jsx(AuthProvider, { children: _jsx(AppContent, {}) }) }));
};
const AppContent = () => {
    const { isAuthenticated, userCity } = useAuth();
    return (_jsxs(Suspense, { fallback: _jsx(LoadingSpinner, {}), children: [isAuthenticated && _jsx(SessionManager, {}), isAuthenticated && _jsx(SocketManager, {}), _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(PrivateRoute, { requireAuth: false, children: _jsx(MyKlinikio, {}) }) }), _jsx(Route, { path: "/login", element: _jsx(PrivateRoute, { requireAuth: false, children: _jsx(LoginForm, {}) }) }), VALID_CITIES.map((cityName) => (_jsxs(React.Fragment, { children: [_jsx(Route, { path: `/${cityName}/student/:username`, element: _jsx(PrivateRoute, { role: "student", allowedCity: cityName, checkUser: true, children: _jsx(StudentUI, {}) }) }), _jsx(Route, { path: `/${cityName}/teacher/:username`, element: _jsx(PrivateRoute, { role: "teacher", allowedCity: cityName, checkUser: true, children: _jsx(TeacherUI, {}) }) }), _jsx(Route, { path: `/${cityName}/notification/*`, element: _jsx(PrivateRoute, { role: "nurse", allowedCity: cityName, checkUser: true, children: _jsx(NotificationUI, {}) }) })] }, cityName))), _jsx(Route, { path: "/unauthorized", element: _jsx("div", { className: "flex items-center justify-center min-h-screen", children: _jsxs("div", { className: "text-center", children: [_jsx("h1", { className: "text-2xl font-bold text-red-600", children: "Unauthorized Access" }), _jsx("p", { className: "mt-2 text-gray-600", children: "You don't have permission to access this page." }), _jsx("button", { onClick: () => window.location.href = '/login', className: "mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600", children: "Return to Login" })] }) }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] })] }));
};
export default App;
