import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '@/api/api.client';
;
const AuthContext = createContext(undefined);
const secureStorage = {
    setItem: (key, value) => {
        try {
            localStorage.setItem(key, value);
        }
        catch (error) {
            console.error('Error storing item:', error);
        }
    },
    getItem: (key) => {
        try {
            return localStorage.getItem(key);
        }
        catch (error) {
            console.error('Error retrieving item:', error);
            return null;
        }
    },
    removeItem: (key) => {
        localStorage.removeItem(key);
    }
};
export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [authState, setAuthState] = useState({
        isAuthenticated: !!sessionStorage.getItem('token'),
        userCity: sessionStorage.getItem('userCity'),
        user: null,
        isLoading: true,
        error: null
    });
    const validateToken = useCallback(async (token) => {
        try {
            const response = await apiClient.get('/api/auth/check');
            return response.success && response.data?.authenticated === true;
        }
        catch {
            return false;
        }
    }, []);
    useEffect(() => {
        const initializeAuth = async () => {
            const token = secureStorage.getItem('token');
            if (token) {
                const isValid = await validateToken(token);
                if (!isValid) {
                    await logout();
                }
            }
            setAuthState(prev => ({ ...prev, isLoading: false }));
        };
        initializeAuth();
    }, [validateToken]);
    const login = useCallback(async (token, role, userId, email, city, firstName, lastName, location, status, loginTimes, lastLogin) => {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('role', role.join(','));
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('userCity', city.toLowerCase());
        sessionStorage.setItem('location', location.join(','));
        const user = {
            userId,
            email,
            role,
            city,
            firstName,
            lastName,
            location,
            edu: '',
            loginTimes,
            loginAttempts: 0,
            lastLogin,
            lastFailedLogin: null,
            tokenVersion: 0,
            status
        };
        setAuthState(prev => ({
            ...prev,
            isAuthenticated: true,
            userCity: city.toLowerCase(),
            user,
            error: null
        }));
    }, []);
    const logout = useCallback(async () => {
        try {
            // First try to clear backend session
            try {
                await apiClient.post('/api/auth/logout', {});
            }
            catch (error) {
                // Log but don't throw - we want to continue with local cleanup
                console.warn('Backend logout failed:', error);
            }
            // Clear local storage
            sessionStorage.clear();
            localStorage.clear();
            // Reset auth state
            setAuthState({
                isAuthenticated: false,
                userCity: null,
                user: null,
                isLoading: false,
                error: null
            });
            // Clean up socket connection
            if (window.socket) {
                try {
                    window.socket.disconnect();
                }
                catch (error) {
                    console.warn('Socket disconnect error:', error);
                }
            }
            // Always navigate to login
            navigate('/login');
        }
        catch (error) {
            console.error('Logout cleanup error:', error);
            // Force navigation to login even if cleanup fails
            navigate('/login');
        }
    }, [navigate]);
    const refreshToken = useCallback(async () => {
        try {
            const response = await apiClient.post('/api/auth/refresh', {});
            if (response.success && response.data?.token) {
                secureStorage.setItem('token', response.data.token);
                return true;
            }
            return false;
        }
        catch (error) {
            console.error('Token refresh failed:', error);
            await logout();
            return false;
        }
    }, [logout]);
    const clearError = useCallback(() => {
        setAuthState(prev => ({ ...prev, error: null }));
    }, []);
    useEffect(() => {
        const checkTokenExpiration = async () => {
            const token = secureStorage.getItem('token');
            if (token) {
                try {
                    const payload = JSON.parse(atob(token.split('.')[1]));
                    const expiryTime = payload.exp * 1000;
                    if (Date.now() >= expiryTime - 5 * 60 * 1000) {
                        const success = await refreshToken();
                        if (!success) {
                            await logout();
                        }
                    }
                }
                catch (error) {
                    console.error('Token validation error:', error);
                    await logout();
                }
            }
        };
        const interval = setInterval(checkTokenExpiration, 60000);
        return () => clearInterval(interval);
    }, [refreshToken, logout]);
    if (authState.isLoading) {
        return _jsx("div", { children: "Loading..." });
    }
    return (_jsx(AuthContext.Provider, { value: {
            ...authState,
            login,
            logout,
            refreshToken,
            clearError
        }, children: children }));
};
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
export default AuthContext;
