import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './LoginAuth';
;
import apiClient from '@/api/api.client';
import { AxiosError } from 'axios';
const PrivateRoute = ({ children, role, allowedCity, checkUser = false, requireAuth = true }) => {
    const { isAuthenticated, userCity, user, logout } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        console.log('PrivateRoute Debug:', {
            isAuthenticated,
            userCity,
            allowedCity,
            path: location.pathname,
            userEmail: user?.email,
            userRoles: user?.role,
            checkingUser: checkUser
        });
    }, [isAuthenticated, userCity, allowedCity, location, user, checkUser]);
    // Validate session on mount and when dependencies change
    useEffect(() => {
        const validateSession = async () => {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    await logout();
                    return;
                }
                // For paths that don't need auth, don't validate
                if (!requireAuth && location.pathname === '/') {
                    return;
                }
                // Validate token and handle 401 errors
                try {
                    const response = await apiClient.get('/api/auth/check');
                    if (!response.success || !response.data?.authenticated) {
                        await logout();
                    }
                }
                catch (error) {
                    // Type guard for AxiosError
                    if (error instanceof AxiosError) {
                        if (error.response?.status === 401) {
                            await logout();
                        }
                    }
                    else {
                        console.error('Unexpected error during auth check:', error);
                        await logout();
                    }
                }
            }
            catch (error) {
                console.error('Session validation error:', error);
                await logout();
            }
        };
        validateSession();
    }, [requireAuth, logout, location.pathname]);
    // Don't check auth for main page
    if (location.pathname === '/' && !requireAuth) {
        return _jsx(_Fragment, { children: children });
    }
    // Handle auth checking for protected routes
    if (requireAuth && !isAuthenticated) {
        return _jsx(Navigate, { to: "/login", state: { from: location }, replace: true });
    }
    // Handle city access check
    if (allowedCity && userCity !== allowedCity) {
        console.warn(`Unauthorized city access attempt: ${userCity} -> ${allowedCity}`);
        return _jsx(Navigate, { to: "/unauthorized", replace: true });
    }
    // Handle role checks
    if (role && (!user || !user.role)) {
        console.error('User or user role data missing');
        return _jsx(Navigate, { to: "/login", replace: true });
    }
    if (role && user) {
        const storedRoles = user.role;
        const hasRequiredRole = validateUserRole(storedRoles, role);
        if (!hasRequiredRole) {
            console.warn(`Unauthorized role access attempt: ${storedRoles} -> ${role}`);
            return _jsx(Navigate, { to: "/unauthorized", replace: true });
        }
        // Location check
        if (!user.location.includes('opetusklinikka')) {
            console.error('Invalid location access attempt');
            return _jsx(Navigate, { to: "/unauthorized", replace: true });
        }
        // Special case for nurse role
        const baseRole = getBaseRole(storedRoles[0]);
        if (baseRole === 'nurse' && (role === 'teacher' || role === 'student')) {
            console.error('Invalid role access attempt: nurse -> teacher/student');
            return _jsx(Navigate, { to: "/unauthorized", replace: true });
        }
    }
    // Handle username match if required
    if (checkUser && user) {
        const userEmail = user.email;
        const userNameFromPath = location.pathname.split('/').pop();
        const userNameFromEmail = userEmail?.split('@')[0]?.replace(/\./g, '');
        console.log('Username check:', {
            pathUsername: userNameFromPath,
            emailUsername: userNameFromEmail,
            fullPath: location.pathname
        });
        if (!userNameFromPath || !userNameFromEmail || userNameFromPath !== userNameFromEmail) {
            console.error('Username mismatch or invalid:', {
                pathUsername: userNameFromPath,
                emailUsername: userNameFromEmail
            });
            return _jsx(Navigate, { to: "/unauthorized", replace: true });
        }
    }
    return _jsx(_Fragment, { children: children });
};
function validateUserRole(userRoles, requiredRole) {
    if (!Array.isArray(userRoles) || !requiredRole) {
        console.error('Invalid role data:', { userRoles, requiredRole });
        return false;
    }
    return userRoles.some(userRole => {
        if (typeof userRole !== 'string') {
            console.error('Invalid user role type:', userRole);
            return false;
        }
        // Check for exact role match
        if (userRole === requiredRole)
            return true;
        // Check for admin version of role
        if (userRole === `${requiredRole}+admin`)
            return true;
        // Check if user is admin with appropriate base role
        const [baseRole, isAdmin] = userRole.split('+');
        if (isAdmin === 'admin' && baseRole === requiredRole)
            return true;
        return false;
    });
}
function getBaseRole(role) {
    return role.split('+')[0];
}
export default PrivateRoute;
